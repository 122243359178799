var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.payment.type == "documentUpload" ||
      (_vm.payment.price &&
        _vm.payment.price.amount > 0 &&
        _vm.payment.price["paymentMethod"] &&
        Object.keys(_vm.payment.price).length != 0)
        ? _c(
            "v-card",
            {
              staticClass: "mx-0 my-2 pointer payment-row",
              class: {
                "selected-row": _vm.isSelected,
                "disabled-row": _vm.isDisabled
              },
              attrs: { disabled: _vm.isDisabled },
              on: {
                click: function($event) {
                  _vm.paymentDetailDialog = true
                }
              }
            },
            [
              _c(
                "v-list-item",
                {
                  staticClass: "payment-row__item",
                  on: {
                    click: function($event) {
                      return _vm.$emit("toggle", _vm.payment)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "item-full-width d-flex flex-column" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-space-between align-center"
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "body-1 font-weight-black white--text ma-0 mr-3 price--amount",
                                class: [_vm.dynamicPrice]
                              },
                              [
                                _vm.payment.type == "documentUpload"
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "white--text" },
                                          [_vm._v("mdi-account-school-outline")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm.payment.price["amount"]
                                  ? _c("span", [
                                      _vm._v(
                                        "€ " +
                                          _vm._s(_vm.payment.price["amount"])
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _vm.payment.type == "extraPayment"
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "body-1 white--text ma-0 text-left price--label"
                                  },
                                  [_vm._v(_vm._s(_vm.payment.label))]
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass:
                                      "body-1 white--text ma-0 text-left price--label"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "general.payment-types." +
                                            _vm.payment.label
                                        )
                                      )
                                    )
                                  ]
                                )
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm.isDisabled
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "payment-check body-1 ma-0 pr-3 grey--text text--lighten-1"
                                    },
                                    [
                                      _vm.payment.price.paymentMethod ===
                                      "recurring"
                                        ? _c(
                                            "span",
                                            { staticClass: "text-uppercase" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "general.payment.payedRecurring"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm.payment.price.paymentMethod ===
                                          "onetimepayment"
                                        ? _c(
                                            "span",
                                            { staticClass: "text-uppercase" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "general.payment.payed"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "text-uppercase" },
                                            [
                                              _vm.payment.status &&
                                              _vm.payment.checked
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.payment.confirmed"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.payment.waiting"
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ]
                                          )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isDisabled &&
                              _vm.payment.status &&
                              _vm.payment.checked
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "ma-0",
                                      attrs: {
                                        medium: "",
                                        left: "",
                                        color: "green accent-2"
                                      }
                                    },
                                    [_vm._v("mdi-check-circle")]
                                  )
                                : _vm.isDisabled
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "ma-0",
                                      attrs: {
                                        medium: "",
                                        left: "",
                                        color: "orange accent-2"
                                      }
                                    },
                                    [_vm._v("mdi-pause-circle")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.paymentDetailDialog,
            callback: function($$v) {
              _vm.paymentDetailDialog = $$v
            },
            expression: "paymentDetailDialog"
          }
        },
        [
          _c("payment-detail", {
            attrs: { payment: _vm.payment, showError: _vm.showError },
            on: {
              close: function($event) {
                _vm.paymentDetailDialog = false
              },
              uploadFile: _vm.fileChange,
              uploadDetails: _vm.updatePayment,
              togglePayment: _vm.togglePayment,
              removeError: _vm.removeError,
              "update:showError": function($event) {
                _vm.showError = $event
              },
              "update:show-error": function($event) {
                _vm.showError = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }