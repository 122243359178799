var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _c(
        "v-layout",
        {
          staticClass: "pb-5 justify-center align-center-img ",
          attrs: { row: "", wrap: "" }
        },
        [
          _c(
            "div",
            { staticClass: "progress-load" },
            [
              _vm.showLoading
                ? _c("v-progress-circular", {
                    attrs: { size: 30, color: "primary", indeterminate: "" }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.showError
            ? _c("v-alert", {
                staticClass: "error-box p-error-box",
                attrs: { color: "red", text: "", type: "warning" },
                domProps: { innerHTML: _vm._s(_vm.$t("general.payment.error")) }
              })
            : _vm._e(),
          _vm.showError
            ? _c("v-img", {
                staticClass: "svg",
                attrs: {
                  alt: "checkMark",
                  src: require("@assets/failed.svg"),
                  contain: "",
                  height: "50vh",
                  width: "40px"
                }
              })
            : _c("v-img", {
                staticClass: "svg",
                attrs: {
                  alt: "checkMark",
                  src: require("@assets/success.svg"),
                  contain: "",
                  height: "50vh",
                  width: "40px"
                }
              })
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "pb-5 justify-center ", attrs: { row: "", wrap: "" } },
        [_c("h1", { staticClass: "white--text" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }