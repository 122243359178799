var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-layout", { staticClass: "detail" }, [
    _c(
      "div",
      { staticClass: "detail__content" },
      [
        _vm.payment.type == "documentUpload"
          ? _c("h1", { staticClass: "detail__content__title" }, [
              _vm._v(_vm._s(_vm.$t(_vm.payment.label)))
            ])
          : _vm.payment.type == "extraPayment"
          ? _c("h1", { staticClass: "detail__content__title" }, [
              _vm._v(
                _vm._s(_vm.payment.label) +
                  " : €" +
                  _vm._s(_vm.payment.price.amount)
              )
            ])
          : _c("h1", { staticClass: "detail__content__title" }, [
              _vm._v(
                _vm._s(_vm.$t("general.payment-types." + _vm.payment.label)) +
                  " : €" +
                  _vm._s(_vm.payment.price.amount)
              )
            ]),
        _vm.payment.type == "documentUpload"
          ? _c("div", {
              staticClass: "detail__content__text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("general.document." + _vm.payment.label, {
                    startDate: "" + _vm.contractPeriod.startDate,
                    endDate: "" + _vm.contractPeriod.endDate
                  })
                )
              }
            })
          : _c("div", {
              staticClass: "detail__content__text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t(
                    "general.payment-types." +
                      (_vm.payment.type == "extraPayment"
                        ? "extraPayment."
                        : "") +
                      _vm.payment.price["paymentMethod"],
                    {
                      type: _vm.$t(
                        "general.payment-types." + _vm.payment.key + "-text"
                      ),
                      bankAccountNumber: _vm.payment.price["bankAccount"],
                      bankAccountName: _vm.payment.price["currentOwner"]
                    }
                  )
                )
              }
            }),
        _vm.payment.type == "documentUpload"
          ? _c(
              "v-card-actions",
              [
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { staticClass: "file-container" },
                      [
                        _c("v-file-input", {
                          staticClass: "file-container__input",
                          attrs: {
                            "background-color": "rgba(60, 60, 127, 0.1)",
                            color: "#3c3c7f",
                            "show-size": "",
                            outlined: "",
                            "prepend-inner-icon": "mdi-paperclip",
                            "prepend-icon": "",
                            accept: "image/*,.pdf",
                            placeholder: _vm.$t("general.document.proof"),
                            "hide-details": "true"
                          },
                          model: {
                            value: _vm.file,
                            callback: function($$v) {
                              _vm.file = $$v
                            },
                            expression: "file"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm.payment.price["paymentMethod"] === "ongoingtask" ||
            _vm.payment.price["paymentMethod"] === "onetimetask"
          ? _c(
              "v-card-actions",
              {},
              [
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { staticClass: "file-container" },
                      [
                        _c("v-file-input", {
                          staticClass: "file-container__input",
                          attrs: {
                            "background-color": "rgba(60, 60, 127, 0.1)",
                            color: "#3c3c7f",
                            "show-size": "",
                            outlined: "",
                            "prepend-inner-icon": "mdi-paperclip",
                            "prepend-icon": "",
                            accept: "image/*,.pdf",
                            placeholder: _vm.$t("general.payment.proof"),
                            "hide-details": "true"
                          },
                          model: {
                            value: _vm.file,
                            callback: function($$v) {
                              _vm.file = $$v
                            },
                            expression: "file"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm.payment.price["paymentMethod"] === "recurring"
          ? _c(
              "v-card-actions",
              {
                staticClass:
                  "mollie-banner d-flex justify-center align-baseline mb-n10"
              },
              [
                _c("div", { staticClass: "ml-2" }, [
                  _c("span", { staticStyle: { "padding-bottom": "5px" } }, [
                    _vm._v(_vm._s(_vm.$t("general.payment.pay-with-twikey")))
                  ])
                ]),
                _c("v-img", {
                  staticClass: "ml-2",
                  staticStyle: {
                    "max-height": "26px",
                    "max-width": "88px",
                    padding: "20px"
                  },
                  attrs: { src: _vm.twikeyLogo }
                })
              ],
              1
            )
          : _c(
              "v-card-actions",
              {
                staticClass:
                  "mollie-banner d-flex justify-center align-baseline mb-n10"
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("general.payment.pay-with-mollie")) + " "
                ),
                _c("v-img", {
                  staticClass: "ml-2",
                  attrs: {
                    src: _vm.mollieLogo,
                    "max-width": "44px",
                    "max-height": "13px"
                  }
                })
              ],
              1
            ),
        _vm.showError
          ? _c("v-alert", {
              staticClass: "error-box",
              attrs: { color: "red", text: "", type: "warning" },
              domProps: { innerHTML: _vm._s(_vm.$t("general.payment.error")) }
            })
          : _vm._e(),
        _vm.showError
          ? _c("v-btn", {
              staticClass: "mt-2",
              domProps: { innerHTML: _vm._s(_vm.$t("general.payment.retry")) },
              on: { click: _vm.retryPayment }
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "confirm" },
          [
            _vm.payment.type == "documentUpload"
              ? _c("v-btn", {
                  staticClass: "confirm__button",
                  attrs: {
                    disabled: !_vm.file,
                    loading: _vm.loading,
                    depressed: ""
                  },
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("general.payment.send"))
                  },
                  on: {
                    click: function($event) {
                      return _vm.confirmData()
                    }
                  }
                })
              : (!_vm.showError &&
                  _vm.payment.price["paymentMethod"] === "ongoingtask") ||
                _vm.payment.price["paymentMethod"] === "onetimetask"
              ? _c("v-btn", {
                  staticClass: "confirm__button",
                  attrs: {
                    disabled: !_vm.file,
                    loading: _vm.loading,
                    depressed: ""
                  },
                  domProps: { innerHTML: _vm._s(_vm.confirmText) },
                  on: {
                    click: function($event) {
                      return _vm.confirmData()
                    }
                  }
                })
              : !_vm.showError
              ? _c("v-btn", {
                  staticClass: "confirm__button",
                  attrs: { loading: _vm.loading, depressed: "" },
                  domProps: { innerHTML: _vm._s(_vm.confirmText) },
                  on: {
                    click: function($event) {
                      return _vm.confirmData()
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "p",
        {
          staticClass: "bottom__close ma-0 d-flex",
          on: {
            click: function($event) {
              return _vm.closeModal()
            }
          }
        },
        [
          _c("v-icon", [_vm._v("mdi-menu-left")]),
          _vm._v(" " + _vm._s(_vm.$t("general.payment.back")) + " ")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }