var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.company === "upkot" && !_vm.paymentDue.allPaid
    ? _c("div", { staticClass: "calendar" }, [
        _c("div", { class: ["calendar-date", _vm.paymentDue.statusCode] }, [
          _c("p", { staticClass: "calendar--text ma-0" }, [
            _vm._v(_vm._s(_vm.paymentDue.daysLeft))
          ])
        ]),
        _c("div", { staticClass: "calendar-info" }, [
          _c("p", {
            staticClass: "calendar--text ma-0",
            domProps: { innerHTML: _vm._s(_vm.$t("general.payment.countdown")) }
          }),
          _vm.paymentDue.daysLeft < 7
            ? _c(
                "span",
                { staticClass: "calendar-info__warning" },
                [
                  _c("v-icon", { attrs: { color: "#FF8A80" } }, [
                    _vm._v(" mdi-alert ")
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }