var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-container" },
    [
      _c(
        "v-row",
        { staticClass: "d-flex fill-height ma-0 justify-center" },
        [
          _c(
            "v-col",
            {
              staticClass:
                "d-flex flex-column justify-center pa-15 grid-container__content"
            },
            [
              _c("v-img", {
                staticClass: "svg",
                attrs: {
                  alt: "checkMark",
                  src: require("@assets/kotkip.svg"),
                  contain: "",
                  height: "161px",
                  width: "130px"
                }
              }),
              _c(
                "h2",
                { staticClass: "passport--title passport--width white--text" },
                [
                  _c("span", {
                    staticClass: "passport--title__black d-block",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("general.payment.passport-title")
                      )
                    }
                  }),
                  _c("span", {
                    staticClass: "passport--title__red d-block",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("general.payment.passport-sub-title")
                      )
                    }
                  })
                ]
              ),
              _c("p", {
                staticClass:
                  "body-1 passport--width passport--text white--text ma-0",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("general.payment-user-info.intro"))
                }
              }),
              _c("p", {
                staticClass:
                  "body-1 passport--width passport--text white--text ma-0",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("general.payment-user-info.passport")
                  )
                }
              }),
              _c("information-page", { attrs: { company: _vm.company } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }