var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _c(
        "v-layout",
        { staticClass: "pb-5 justify-center", attrs: { row: "", wrap: "" } },
        [
          _c("v-img", {
            staticClass: "svg",
            attrs: {
              alt: "checkMark",
              src: require("@assets/success.svg"),
              contain: "",
              height: "50vh",
              width: "40px"
            }
          })
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "pb-5 justify-center", attrs: { row: "", wrap: "" } },
        [
          _c("h1", { staticClass: "white--text" }, [
            _vm._v("Betaling succesvol")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }