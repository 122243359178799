import { render, staticRenderFns } from "./payment-detail.vue?vue&type=template&id=523f51d6&scoped=true&"
import script from "./payment-detail.vue?vue&type=script&lang=js&"
export * from "./payment-detail.vue?vue&type=script&lang=js&"
import style0 from "./payment-detail.vue?vue&type=style&index=0&id=523f51d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523f51d6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCardActions,VFileInput,VFlex,VIcon,VImg,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('523f51d6')) {
      api.createRecord('523f51d6', component.options)
    } else {
      api.reload('523f51d6', component.options)
    }
    module.hot.accept("./payment-detail.vue?vue&type=template&id=523f51d6&scoped=true&", function () {
      api.rerender('523f51d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/payment/list/payment-detail.vue"
export default component.exports